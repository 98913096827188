.highlight-line {
  display: inline-block;
  
  /* del, ins, mark default styles */
  text-decoration: none;
  color: inherit;
}
  
/* allow highlighting empty lines */
.highlight-line:empty:before {
  content: " ";
}
  
.highlight-line:not(:last-child) {
  min-width: 100%;
}
.highlight-line .highlight-line:not(:last-child) {
  min-width: 0;
}
  
  
/*
 * Dark theme
 */
  
.highlight-line-isdir {
  color: #b0b0b0;
  background-color: #222;
}
.highlight-line-active {
  background-color: #444;
  background-color: hsla(0, 0%, 27%, .8);
}
.highlight-line-add {
  background-color: #45844b;
}
.highlight-line-remove {
  background-color: #902f2f;
}