//source: https://github.com/willianjusten/cards-jekyll-template/blob/master/src/styl/_author.styl

.author {
  display: table;
  margin: auto;
  width: 100%;
  min-height: 90px;
  margin-bottom: 16px;

  .avatar {
    float: left;
    margin-right: 10px;

    img {
      display: block;
      margin: auto;
      border-radius: 50%;
    }

  }

  .content {
    padding: 8px;
    vertical-align: middle;
    letter-spacing: 0.1em;

    h3 {
      font-size: 80%;
      padding: 0;
      margin: 0;
      line-height: 18px;
    }

    p {
      font-size: 80%;
      padding: 0;
      margin: 0;
    }

  }

}
